import { Links, Meta, Outlet, Scripts, ScrollRestoration } from 'react-router';
import '@synoptic/ui-kit/normalize';
import { themeClass } from '@synoptic/ui-kit/theme.css.js';
import { ToastProvider } from '@synoptic/ui-kit/toast/toast-provider.js';
import { makeToastQueue } from '@synoptic/ui-kit/toast/toast-queue.js';
import { Toaster } from '@synoptic/ui-kit/toast/toaster.js';
import { ErrorPage } from './components/error-page/error-page.tsx';
import './entry.css.ts';
import {
  AWS_COGNITO_USER_POOL_ENDPOINT,
  AWS_GRAPHQL_API_URL,
  AWS_S3_BUCKET_NAME,
} from './env.ts';
import { getPageMeta } from './routes-utils/get-page-meta.ts';
import { GlobalProgressBar } from './components/global-progress-bar/global-progress-bar.tsx';
import { TooltipProvider } from '@synoptic/ui-kit/tooltip/tooltip.js';
import {
  getActiveSpan,
  spanToBaggageHeader,
  spanToTraceHeader,
} from '@sentry/aws-serverless';

import { Route } from './+types/root.ts';

const toastQueue = makeToastQueue();

export const ErrorBoundary = () => <ErrorPage withContactUs />;

export const loader = () => {
  const span = getActiveSpan();

  if (!span) return null;

  return {
    traceHeader: spanToTraceHeader(span),
    baggageHeader: spanToBaggageHeader(span),
  };
};

export const clientLoader = () => {};

export const meta = ({ data }: Route.MetaArgs) => {
  const meta = getPageMeta();

  if (data) {
    meta.push({ name: 'sentry-trace', content: data.traceHeader });
    meta.push({ name: 'baggage', content: data.baggageHeader });
  }

  return meta;
};

export const Layout = ({ children }: { children: React.ReactNode }) => {
  return (
    <html lang="en" className={themeClass}>
      <head>
        <meta charSet="utf-8" />
        {import.meta.env.MODE !== 'production' ? (
          <meta name="robots" content="noindex, nofollow" />
        ) : null}
        <link rel="icon" href="/static/favicon-v2.ico" sizes="32x32" />
        <link
          id="svgIcon"
          rel="icon"
          href={`/static/icon-${import.meta.env.MODE || 'development'}-v2.svg`}
          type="image/svg+xml"
        />
        <link rel="apple-touch-icon" href="/static/apple-touch-icon-v2.png" />
        <link rel="manifest" href="/static/manifest.json" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=0,viewport-fit=cover"
        />

        <link
          rel="preconnect"
          href="https://cognito-identity.us-east-1.amazonaws.com"
        />
        <link rel="preconnect" href={new URL(AWS_GRAPHQL_API_URL).origin} />
        <link
          rel="preconnect"
          href={new URL(AWS_COGNITO_USER_POOL_ENDPOINT).origin}
        />
        <link rel="preconnect" href={`https://${AWS_S3_BUCKET_NAME}`} />

        <Meta />
        <Links />
      </head>
      <body>
        <script
          // hack for https://github.com/awslabs/aws-mobile-appsync-sdk-js/issues/426
          dangerouslySetInnerHTML={{
            __html: `
          if (global === undefined) {
            var global = window;
          }
          `,
          }}
        ></script>
        <div id="root">
          {/* TODO: probably better to wrap only layouts where it's needed */}
          <GlobalProgressBar />
          <ToastProvider toastQueue={toastQueue}>
            <TooltipProvider delayDuration={500}>{children}</TooltipProvider>
            <Toaster />
          </ToastProvider>

          <ScrollRestoration
            getKey={(location) => {
              const paths = ['/'];

              return paths.includes(location.pathname)
                ? location.pathname
                : location.key;
            }}
          />
          <Scripts />
        </div>
      </body>
    </html>
  );
};

export default function Root() {
  return <Outlet />;
}
